<template>
  <div v-if="partners.length > 1">
    <div class="dropdown-divider"></div>
    <div class="dropdown-header">Partner Accounts</div>
    <a
      v-for="partner in partners"
      :key="partner.partnerId"
      class="dropdown-item d-flex align-items-center"
      href="/"
      @click.prevent="setPartnerId(partner.partnerId)"
    >
      <span class="partner-name mx-1">{{ partner.name }}</span>
      <i
        v-if="appData.selectedPartnerId === partner.partnerId"
        class="fa-solid fa-circle-check fa-fw ms-auto text-gray-400 fs-16px"
      ></i>
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import bff from "@/hooks/bff";
import { useAppDataStore } from "@/stores/app-data";

interface Partner {
  partnerId: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export default defineComponent({
  setup() {
    return {
      appData: useAppDataStore(),
    };
  },
  data() {
    return {
      partners: [] as Partner[],
    };
  },
  mounted() {
    if (this.appData.authorizedPartnerIds.length) {
      this.fetchAuthorizedPartners();
    }
  },
  methods: {
    async fetchAuthorizedPartners() {
      await bff
        .post("/v1/getAuthorizedPartners")
        .then((r) => {
          const partners = r?.data?.data || [];

          this.partners = partners;
        })
        .catch((e) => console.error(e));
    },
    setPartnerId(partnerId: string) {
      if (partnerId === this.appData.selectedPartnerId) {
        return;
      }

      this.appData.setSelectedPartnerId(partnerId);

      // Same as location.reload(), fetch new data after changing partnerId.
      this.$nextTick(() => {
        this.$router.go(0);
      });
    },
  },
});
</script>

<style scoped>
.partner-name {
  flex-grow: 1;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
