import type { PiniaPluginContext } from "pinia";
import type { AppDataStoreState } from "@/stores/app-data";
import type { AppUserStoreUser } from "@/stores/app-user";

const LS_APP_DATA_STORE_KEY = "partnerPortal:data";

export default function PersistAppDataStorePlugin(context: PiniaPluginContext) {
  if (context.store.$id !== "appData") {
    return {};
  }

  context.store.$onAction((event) => {
    if (event.name === "resetStore") {
      localStorage.removeItem(LS_APP_DATA_STORE_KEY);
    }

    if (event.name === "setSelectedPartnerId") {
      const [partnerId] = event.args;

      localStorage.setItem(
        LS_APP_DATA_STORE_KEY,
        JSON.stringify({ selectedPartnerId: partnerId }),
      );
    }
  });

  const appData = JSON.parse(
    localStorage.getItem(LS_APP_DATA_STORE_KEY) || "{}",
  ) as AppDataStoreState;

  if (appData?.selectedPartnerId) {
    context.store.setSelectedPartnerId(appData.selectedPartnerId);
  }
  // Else if we don't have a selected partner ID in local storage, set the first partner ID from the user's permissions.
  else {
    const userData = JSON.parse(
      localStorage.getItem("partnerPortal:user") || "{}",
    ) as AppUserStoreUser;

    const firstPartnerId = userData?.permissions?.[
      "client:usePartnerPortal"
    ]?.[0]
      ?.split(":")
      ?.pop();

    if (firstPartnerId) {
      context.store.setSelectedPartnerId(firstPartnerId);
    }
  }

  return {};
}
