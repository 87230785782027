import { defineStore } from "pinia";
import { useAppUserStore } from "@/stores/app-user";
import usStatesList from "@/assets/json/us-states-list.json";

export interface AppDataStoreState {
  locations: PMC.Parking.Location[];
  selectedPartnerId: string;
}

export const useAppDataStore = defineStore({
  id: "appData",
  state: () => {
    return {
      locations: [],
      selectedPartnerId: "",
    };
  },
  getters: {
    authorizedPartnerIds(): string[] {
      const appUserStore = useAppUserStore();

      return (
        appUserStore?.user?.permissions?.["client:usePartnerPortal"]?.map(
          (permission) => permission.split(":").pop() as string,
        ) || []
      );
    },
    usStates() {
      return usStatesList;
    },
  },
  actions: {
    setSelectedPartnerId(partnerId: string) {
      this.selectedPartnerId = partnerId;
    },
    resetStore() {
      this.$reset();
    },
  },
});
