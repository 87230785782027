import axios from "axios";
import type { InternalAxiosRequestConfig } from "axios";
import { useAppUserStore } from "@/stores/app-user";

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
});

// Interceptor adds Authorization header with bearer token to all requests that require auth.
axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const appUserStore = useAppUserStore();
  const token = appUserStore.accessToken;

  if (!token) {
    const controller = new AbortController();

    const cfg = {
      ...config,
      signal: controller.signal,
    };

    controller.abort("Cannot perform request without access token.");

    return cfg;
  }

  if (token) config.headers.Authorization = `Bearer ${token}`;

  return config;
});

interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: InternalAxiosRequestConfig;
}

// List to hold the request queue while we're refreshing the access token.
const refreshAndRetryQueue: RetryQueueItem[] = [];

// Flag used in response interceptor to prevent multiple token refresh requests.
let isRefreshing = false;

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest: InternalAxiosRequestConfig = error.config;

    if (
      error.response &&
      error.response.status === 403 &&
      error.response?.data?.message === "Forbidden"
    ) {
      console.warn("403 error, refreshing token...", error.response.data);
      if (!isRefreshing) {
        const appUserStore = useAppUserStore();

        isRefreshing = true;

        try {
          const newAccessToken = await appUserStore.postRefreshToken();

          error.config.headers.Authorization = `Bearer ${newAccessToken}`;

          // Retrying all requests in the queue with the new token, then clearing the queue.
          refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            axiosInstance
              .request(config)
              .then((response) => resolve(response))
              .catch((err) => reject(err));
          });

          refreshAndRetryQueue.length = 0;

          // Retrying the original request.
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          appUserStore.signOut();

          // Game over. Redirect to login page if refresh token fails.
          window.alert("Your session has expired. Please log in again.");
          window.location.href = "/login";
        } finally {
          isRefreshing = false;
        }
      }

      // Add unauthorized request to the queue.
      return new Promise<void>((resolve, reject) => {
        refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
      });
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
